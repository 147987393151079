import styled, { css, keyframes } from 'styled-components'
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained'
import Typography from '@ntuctech/devex-tangram/Typography'
import Radio from '@ntuctech/devex-tangram/Radio'
import { SCREEN_SIZE } from './Media'

export const RadioButton = styled(Radio)`
  margin-right: 0.5rem;

  ${SCREEN_SIZE.From.Tablet} {
    margin-right: 1rem;
  }
`

export const Arrow = styled.div`
  position: relative;
  display: inline-block;

  ${({ size }) => {
    if (size === 's') {
      return 'width: 0.35rem; height: 0.35rem;'
    }
    return 'width: 0.5rem; height: 0.5rem;'
  }}

  transform: ${({ direction }) => {
    switch (direction) {
      case 'up':
        return 'rotate(0deg)'
      case 'down':
        return 'rotate(180deg)'
      case 'right':
        return 'rotate(90deg)'
      case 'left':
        return 'rotate(-90deg)'
    }
    return 'rotate(0deg)'
  }};

  transition: transform 0.5s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${({ color }) => color || '#333333'};
    border-radius: 0.2rem;
    display: block;
    height: 110%;
    width: 25%;
  }
  &:before {
    left: 68%;
    transform: rotate(-45deg);
  }
  &:after {
    right: 69%;
    transform: rotate(45deg);
  }
`
const pulse = keyframes`
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
`

export const Skeleton = styled.div`
  height: ${props => props.height || '1'}rem;
  width: ${props => `${props.width}rem` || '100%'};
  margin-bottom: ${props => props.mb || '0'}rem;
  margin-left: ${props => props.ml || '0'}rem;
  border-radius: ${props => props.br || '0'}px;
  max-width: 100%;

  background-color: grey;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-name: ${pulse};
`

export const Section = styled.div`
  text-align: center;
  margin: 0 auto 1rem;
`

export const ContentSection = styled(Section)`
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 0.25rem;
  ${SCREEN_SIZE.Only.Mobile} {
    margin: 0 1rem 1rem;
  }
`

export const PaddingBox = styled.div`
  padding: 1rem;

  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: #1557bf;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  margin-top: 0.5rem;
  display: block;
  cursor: pointer;
  &:visited {
    color: #1557bf;
  }
`

export const LinkWrapper = styled.a`
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  color: #333333;
`

export const StyledButton = styled(ButtonContained)`
  display: inline-block;
  margin: 1rem 0 0 0;
`

export const TypographySc = styled(Typography)`
  color: ${p => p.theme.ColorFontLinkBase};
`
