import React, { Fragment } from 'react'
import Link from '@ntuctech/devex-tangram/Link'
import Illustartion1 from '../assets/illustartion1.svg'
import Illustartion2 from '../assets/illustartion2.svg'
import SC from './elements'
import { TabPanel } from '@ntuctech/devex-tangram/Tabs'

const Loader = () => (
  <SC.StyledImageWrapper center>
    <SC.Skeleton width="10" height="0.5" ml="0.2" mb="0.5" />
    <SC.Skeleton width="8" height="0.5" ml="0.2" mb="0.5" />
    <SC.Skeleton width="8" height="0.5" ml="0.2" mb="0.5" />
    <SC.Skeleton width="10" height="0.5" ml="0.2" mb="0.5" />
  </SC.StyledImageWrapper>
)

const HowToUseDetails = ({ imgLoading, dispatch }) => {
  return (
    <Fragment>
      <SC.StyledTabContent usenow>
        <SC.Tabs
          className="rewards"
          onChange={() => dispatch({ type: 'SET_IMAGE_LOADING' })}
        >
          <SC.TabList>
            <SC.StyledTab usenow>Cashier</SC.StyledTab>
            <SC.StyledTab usenow>Self-checkout counters</SC.StyledTab>
          </SC.TabList>
          <TabPanel>
            {imgLoading && <Loader />}
            <SC.HowToUseImage
              src={Illustartion1}
              width="160"
              height="160"
              onLoad={() => dispatch({ type: 'IMAGE_LOADED' })}
            />
            <SC.HowToUseDetails variant="body3">
              Show your QR code to the cashier.
            </SC.HowToUseDetails>
          </TabPanel>
          <TabPanel>
            {imgLoading && <Loader />}
            <SC.HowToUseImage
              src={Illustartion2}
              width="160"
              height="160"
              onLoad={() => dispatch({ type: 'IMAGE_LOADED' })}
            />
            <SC.HowToUseDetails variant="body3">
              <ul>
                To scan your QR code:
                <li>• Select ‘All other payment’</li>
                <li>• Select ‘Partner voucher/FairPrice e-voucher’ </li>
              </ul>
            </SC.HowToUseDetails>
          </TabPanel>
        </SC.Tabs>
      </SC.StyledTabContent>
      <SC.Box m={3}>
        <SC.Typography variant="body3">Need help?</SC.Typography>
        <SC.Typography variant="body3">
          <Link
            size="m"
            href="https://help.fairprice.com.sg/hc/en-us/requests/new?ticket_form_id=360000029352"
            target="_blank"
            rel="nofollow"
            color="#1557BF"
            weight="bold"
          >
            Drop us a message
          </Link>
          . Do mention your voucher <br />
          code in the ‘Tell us more’ field.
        </SC.Typography>
      </SC.Box>
    </Fragment>
  )
}

export default HowToUseDetails
