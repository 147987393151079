import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'qrcode'
import Link from '@ntuctech/devex-tangram/Link'
import SC from './elements'
import VC from '../Checkout/elements'
import HowToUseDetails from './HowToUseDetails'
import WhiteBg from '../assets/white-bg.png'

const QrCodeDetailsPopUp = ({
  voucherDetails,
  dispatch,
  showHowToUse,
  imgLoading,
}) => {
  const canvasRef = useRef()
  const [loaded, setLoaded] = useState(false)

  const initializeCanvas = () => {
    setLoaded(true)
  }
  useEffect(() => {
    initializeCanvas()
    QRCode.toCanvas(canvasRef.current, voucherDetails.code, {
      width: 200,
      margin: 0,
    })
  })
  return (
    <VC.StyledPopup
      className="ndsHidden-s-up"
      data-testid="qrcode-screen"
      forceAcknowledge
    >
      <VC.StyledPopupContainer wallet qrcode>
        <VC.PopupHeader data-back-btn={true} usenow={showHowToUse}>
          <VC.BackBtn
            size="m"
            color="#696969"
            data-testid="qrcode-screen-back-btn"
            onClick={() =>
              dispatch.parentDispatch({
                type: showHowToUse
                  ? 'BACK_TO_QRCODE_SCREEN'
                  : 'BACK_TO_VOUCHER_DETAILS',
                value: voucherDetails,
              })
            }
          />
          <VC.Typography variant="h6">
            {showHowToUse ? 'How to use' : voucherDetails.description}
          </VC.Typography>
          <VC.PopupCloseBtn
            color="#666666"
            onClick={() =>
              dispatch.parentDispatch({
                type: 'HIDE_VOUCHER_DETAILS',
              })
            }
            className="close-btn"
          />
        </VC.PopupHeader>
        {showHowToUse ? (
          <HowToUseDetails
            imgLoading={imgLoading}
            dispatch={dispatch.parentDispatch}
          />
        ) : (
          <SC.QrCodeBox>
            <SC.Typography variant="h6">Balance</SC.Typography>
            <SC.Balance className="balance">
              <span>$</span>
              {voucherDetails.balanceRaw}
            </SC.Balance>
            <SC.Typography variant="subhead1">
              Scan QR code at payment counters
            </SC.Typography>
            <SC.StyledQrCodeWrapper>
              {!loaded && (
                <SC.StyledImageWrapper center>
                  <SC.Skeleton width="16" height="0.5" ml="0.2" mb="0.5" />
                  <SC.Skeleton width="14" height="0.5" ml="0.2" mb="0.5" />
                  <SC.Skeleton width="12" height="0.5" ml="0.2" mb="0.5" />
                  <SC.Skeleton width="10" height="0.5" ml="0.2" mb="0.5" />
                  <SC.Skeleton width="12" height="0.5" ml="0.2" mb="0.5" />
                  <SC.Skeleton width="14" height="0.5" ml="0.2" mb="0.5" />
                  <SC.Skeleton width="16" height="0.5" ml="0.2" mb="0.5" />
                </SC.StyledImageWrapper>
              )}
              <img alt="white bg" src={WhiteBg} />
              <canvas ref={canvasRef} onLoad={initializeCanvas} />
              <SC.QrCode>Voucher code: {voucherDetails.code}</SC.QrCode>
              <SC.Validity
                variant="body4"
                expired={Boolean(voucherDetails.expiresAt.match(/[~]/))}
              >
                {voucherDetails.expiresAt.replace(/[~]/g, '')}
              </SC.Validity>
            </SC.StyledQrCodeWrapper>
            <SC.Box mx={3} mt={1}>
              <Link
                size="l"
                onClick={() => {
                  dispatch.track(
                    dispatch.eventsDataset[
                      dispatch.TRACKER_CONSTANTS
                        .ON_VOUCHER_WALLET_HOW_TO_USE_CLICK
                    ]
                  )
                  dispatch.parentDispatch({ type: 'SHOW_HOWTOUSE_DETAILS' })
                }}
                target="_blank"
                rel="nofollow"
                color="#1557BF"
                weight="bold"
                data-testid="how-to-use-btn"
              >
                How to use
              </Link>
            </SC.Box>
          </SC.QrCodeBox>
        )}
      </VC.StyledPopupContainer>
    </VC.StyledPopup>
  );
}

export default QrCodeDetailsPopUp
