import React from 'react'
import styled from 'styled-components'
import NoImg from '../../components/icons/NoImg'

const NoImage = styled(NoImg)`
  width: 100%;
  height: 100%;
  max-width: 7rem;
  display: block;
  margin: auto;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: center;
  text-align: center;
  overflow: hidden;
  height: ${({ reservedHeight }) => (reservedHeight ? reservedHeight : '100%')};
  width: ${({ reservedWidth }) => (reservedWidth ? reservedWidth : 'auto')};
  margin: ${({ margin }) => (margin ? margin : '0')};
`

const StyledImg = styled.img.attrs(props => ({
  alt: props.altText || '',
}))`
  display: inline-block;
  border-radius: ${props => (props.round ? '50%' : 0)};
  margin-bottom: ${props => (props.caption ? '0.5rem' : 0)};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  ${props => props.type === 'voucher' && 'border-radius: 0.25rem'}
`

const ImageSizeHandler = styled.div`
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
`

class Image extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBrokenImage: false,
    }
  }

  render() {
    const {
      round,
      caption,
      src,
      width,
      height,
      altText,
      align,
      noLazyLoading,
      reservedHeight,
      reservedWidth,
      margin,
      placeholder = NoImage,
      placeholderSrc,
      type,
      ...imgAttributes
    } = this.props

    const { isBrokenImage } = this.state

    if (isBrokenImage && !placeholderSrc) {
      const PlaceHolder = placeholder
      return <PlaceHolder />
    }

    return (
      <StyledContainer
        align={align}
        reservedHeight={reservedHeight}
        reservedWidth={reservedWidth}
        margin={margin}
      >
        <ImageSizeHandler width={width} height={height}>
          <StyledImg
            {...imgAttributes}
            type={type}
            width={width}
            height={height}
            altText={altText}
            src={isBrokenImage && placeholderSrc ? placeholderSrc : src}
            round={round}
            caption={caption}
            loading={noLazyLoading ? '' : 'lazy'}
            onError={() => {
              this.setState({ isBrokenImage: true })
            }}
          />
        </ImageSizeHandler>
        {caption}
      </StyledContainer>
    )
  }
}

Image.defaultProps = {}

export default Image
